import React, { useState } from "react";
import { Link } from "react-router-dom";
import { tabs, useNavbar } from "../../customHooks/Navbar/useNavbar";
import AlertModal from "../Alert/AlertModal";
import MainLogo from "../../assets/images/logo.png";

// Styles Import
import styles from "./NavBar.module.css";

const NavBar = () => {
  const hookProps = useNavbar();
  const { openAlert, handleClose, handleLogout, handleOpen, handleItemClick } =
    hookProps;
  const currentIndex = localStorage.getItem("tabIndex");
  return (
    <div className={styles.VerticalNav}>
      <div className={styles.NavbarExpand}>
        <Link
          to="/"
          className={styles.NavbarBrand}
          onClick={() => handleItemClick("0")}
        >
          <img className={styles.SkyWorthLogo} src={MainLogo} alt="Logo" />
        </Link>
      </div>
      <ul className={styles.SideMenu}>
        {tabs.map((item, index) => {
          switch (item.title) {
            case "Logout":
              return (
                <li className={styles.SideMenuItem} key={index}>
                  <Link
                    onClick={handleOpen}
                    to={location.pathname}
                    className={styles.SideMenuItemLink}
                  >
                    {item.title}
                  </Link>
                </li>
              );
            default:
              return (
                <li className={styles.SideMenuItem} key={index}>
                  <Link
                    to={item.path}
                    className={`${styles.SideMenuItemLink} ${
                      currentIndex == index ? styles.active : ""
                    }`}
                    onClick={() => handleItemClick(index)}
                  >
                    {item.title}
                  </Link>
                </li>
              );
          }
        })}
      </ul>
      <AlertModal
        open={openAlert}
        handleClose={handleClose}
        handleOk={handleLogout}
        title={"Are you sure you want to Logout ?"}
        showCancel={true}
      />
    </div>
  );
};

export default NavBar;
