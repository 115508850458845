export const categoryPages = [
  {
    type: "Income",
    title: "Income",
    path: "/incomes",
  },
  {
    type: "Expense",
    title: "Expense",
    path: "/expenses",
  },
  {
    type: "Savings",
    title: "Savings",
    path: "/savings",
  },
  {
    type: "Goals",
    title: "Goals",
    path: "/goals",
  },
  {
    type: "Videos",
    title: "Video",
    path: "/videos",
  },
  {
    type: "FAQ",
    title: "FAQ",
    path: "/faqs",
  },
];
