export const endpoint = {
  // Api Base Url

  // For Local Environmet
  // API_BASE_URL: "http://localhost:8080/api/V1",

  // For Devolopment Environmet
  // API_BASE_URL: "http://3.111.246.99/api/V1",

  // For Producttion Environmet
  API_BASE_URL: "https://swadhaarfinaccess.org/api/V1",

  // Api Endpoints
  AUTH_LOGIN: "/auth/login",

  GET_API_CONFIG: "/appConfig/getAppConfig",
  GETSCHEMES: "/schemes/getSchemes",
  GETSINGLESCHEME: "/schemes/getSchemeById/",
  ADD_SCHEME: "/schemes/createScheme",
  EDIT_SCHEME: "/schemes/updateSchemeById",
  UPDATE_SCHEME: "/schemes/changeStatusById",
  DELETE_SCHEME: "/schemes/deleteSchemeById/",

  // GETQUERIES: "",
  // EDITQUERYSTATUS: "",

  GETCITIES: "/contact/getStates",
  GETCFLDATA: "/contact/getCFLByState/",
  ADDCFL: "/contact/createCFL",
  EDITCFL: "/contact/updateCFL/",
  GET_SINGLE_CFL: "/contact/getCFLById/",
  UPDATE_CFL_STATUS: "/contact/updateCFLStatusById/",
  DELETE_CFL: "/contact/deleteCFLById/",

  GETCATEGORIES: "/category/getCategoriesByType/",
  GETSINGLECATEGORY: "/category/getCategoryById/",
  GET_CATEGORY_BY_ID: "/category/getCategoryById/",
  EDIT_CATEGORY: "/category/updateCategoryById/",
  ADD_CATEGORY: "/category/createCategoryByType/",
  UPDATE_CATEGORY_STATUS: "/category/updateCategoryStatusById/",
  DELETE_CATEGORY: "",

  GETVIDEOCATEGORIES: "/videos/getVideoByCategory/",
  CREATEVIDEOINCATEGORIES: "/videos/createVideo/",
  GETSINGLEVIDEO: "/videos/getVideoById/",
  EDIT_VIDEO: "/videos/updateVideo/",
  UPDATE_VIDEO_STATUS: "videos/updateVideoStatusById/",
  DELETE_VIDEO_IN_CATEGORIES: "/videos/deleteVideoById/",

  GETCONTENT: "/content/getContent/",
  EDITCONTENT: "/content/updateContent/",

  GETFAQSINCATEGORIES: "/faq/getFAQByCategory/",
  ADD_FAQ: "/faq/createFAQ",
  EDIT_FAQ: "/faq/updateFAQ/",
  DELETE_FAQ_IN_CATEGORIES: "/faq/deleteFAQById/",
  GET_SINGLE_FAQ: "/faq/getFAQById/",

  GET_USER_TRANSACTIONS: "/transactions/getHome",
  GET_USERS: "/user/getUsers",
};
