import { useState } from "react";
const MOBILE_REGEX = /^[6-9]\d{9}$/;
const OTP_REGEX = /^\d{4}$/;
import axios from "axios";
const endPoint = "https://swadhaarfinaccess.org/api/V1";

export const useDeleteAccount = (props) => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [verifyOtpModal, setVerifyOtpModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteAccountSuccess, setDeleteAccountSuccess] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const isValidPhone = () => {
    if (phone.trim() === "") {
      setPhoneError("This is a required field!");
      return false;
    } else if (!MOBILE_REGEX.test(phone)) {
      setPhoneError("Please enter valid phone number");
      return false;
    } else {
      setPhoneError("");
      return true;
    }
  };

  const isValidOtp = () => {
    if (otp.trim() === "") {
      setOtpError("This is a required field!");
      return false;
    } else if (!OTP_REGEX.test(otp)) {
      setOtpError("Please enter valid otp");
      return false;
    } else {
      setOtpError("");
      return true;
    }
  };

  const validate = () => {
    if (!verifyOtpModal) {
      if (isValidPhone()) {
        return true;
      } else {
        isValidPhone();
      }
    } else {
      if (isValidOtp()) {
        return true;
      } else {
        isValidOtp();
      }
    }

    return false;
  };

  const sendOTP = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      setLoading(true);
      const result = await axios.post(`${endPoint}/auth/sendOTP`, {
        dPhoneNum: phone,
      });

      if (result.data.status === "success") {
        if (result.data.data.userExist === false) {
          setPhoneError("User does not exist");
        } else {
          setVerifyOtpModal(true);
          setPhoneError("");
          setAccessToken(result.data.data.otpToken);
        }
      } else {
        setPhoneError(result.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      setPhoneError(error.response.data.message);
    }
  };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const result = await axios.post(
        `${endPoint}/user/deleteUser`,
        {
          dPhoneNum: phone,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (result.data.status === "success") {
        setOpenAlert(false);
        setVerifyOtpModal(false);
        setConfirmModal(false);
        setDeleteAccountSuccess(true);
        setPhone("");
        setOtp("");
        setAccessToken("");
        setSuccessMessage(result.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const VerifyOtp = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }
    console.log("access", accessToken);

    try {
      setLoading(true);
      const result = await axios.post(
        `${endPoint}/verifyOTP`,
        {
          otp: otp,
        },
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (result.data.status === "success") {
        setConfirmModal(true);
        setAccessToken(result.data.data.accessToken);
        setOtpError("");
      } else {
        setOtpError(result.data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOtpError(error.response.data.message);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpenAlert(false);
  };

  const handleModal = async (e) => {
    e.preventDefault();
    setOpenAlert(true);
  };

  return {
    phone,
    otp,
    setPhone,
    setOtp,
    phoneError,
    otpError,
    isValidPhone,
    isValidOtp,
    sendOTP,
    VerifyOtp,
    verifyOtpModal,
    handleModal,
    openAlert,
    handleClose,
    deleteAccountSuccess,
    setVerifyOtpModal,
    handleDeleteAccount,
    confirmModal,
    successMessage,
    setOtpError,
    setAccessToken,
    setConfirmModal,
    loading,
  };
};
