import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiHandler } from "../../api";
import { endpoint } from "../../api/endpoint";
import { setLoader } from "../../features/FullPageLoader/FullPageLoaderSlice";

export const useFAQPage = (props) => {
  const { FAQCategoryId, urlParams } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authData } = useSelector((state) => state.loginSlice);
  const { languageEntries } = useSelector((state) => state.appConfigSlice);
  const [FAQData, setFAQData] = useState(null);
  const [langCode, setLangCode] = useState(languageEntries[0].langCode);
  const paramPageNo = urlParams.get("pageNo");
  const [currentPage, setCurrentPage] = useState(parseInt(paramPageNo) || 1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const onPageChange = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
    const url = new URL(window.location.href);
    url.searchParams.set("pageNo", pageNumber);
    window.location.href = url.toString();
  }, []);

  const getfAQs = useCallback(async () => {
    dispatch(setLoader(true));
    // let url = endpoint.GETFAQSINCATEGORIES + FAQCategoryId;
    // if (currentPage) {
    //   url = `${
    //     endpoint.GETFAQSINCATEGORIES + FAQCategoryId
    //   }?pageNumber=${currentPage}&pageSize=${itemsPerPage}`;
    // }
    const result = await apiHandler({
      url: endpoint.GETFAQSINCATEGORIES + FAQCategoryId,
      method: "GET",
      authToken: authData.accessToken,
      refreshToken: authData && authData.refreshToken,
      headers: { langcode: langCode },
      dispatch,
      navigate,
    });
    if (result.data.status === "success") {
      setFAQData(result.data.data);
      setTotalItemsCount(
        result.data.data && result.data.data.length
          ? result.data.data.length
          : 0
      );
    } else {
      console.log("getFAQs : result.data", result.data);
    }
    dispatch(setLoader(false));
  }, [
    currentPage,
    FAQCategoryId,
    endpoint,
    dispatch,
    authData,
    itemsPerPage,
    langCode,
  ]);

  useEffect(() => {
    getfAQs();
  }, [currentPage, FAQCategoryId, itemsPerPage, langCode]);

  const deleteFAQ = async (Id) => {
    dispatch(setLoader(true));
    const result = await apiHandler({
      url: endpoint.DELETE_FAQ_IN_CATEGORIES + Id,
      method: "DELETE",
      authToken: authData.accessToken,
      refreshToken: authData && authData.refreshToken,
      dispatch,
      navigate,
    });

    if (result.data.status === "success") {
      setFAQData((prevState) => prevState.filter((faq) => faq.iFAQId !== Id));
    }
    dispatch(setLoader(false));
  };

  const handleBack = (event) => {
    event.preventDefault();
    navigate(-1, { replace: true });
  };
  return {
    languageEntries,
    langCode,
    setLangCode,
    FAQData,
    deleteFAQ,
    currentPage,
    itemsPerPage,
    setItemsPerPage,
    totalItemsCount,
    onPageChange,
    handleBack,
  };
};
