import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

// Loader
import FullPageLoading from "./components/FullPageLoading/FullPageLoading";

// Layout
import Layout from "./hocs/Layout";

// Authentication pages
import AuthPage from "./containers/AuthPage/AuthPage";

// Container Imports
const SchemesPage = lazy(() => import("./containers/Schemes/SchemesPage"));
const NotFound = lazy(() => import("./containers/NotFound/NotFound"));
// const QueriesPage = lazy(() => import("./containers/QueriesPage/QueriesPage"));
// const EditQuery = lazy(() => import("./containers/EditQuery/EditQuery"));
const CFLPage = lazy(() => import("./containers/CFLPage/CFLPage"));
const AddCFL = lazy(() => import("./containers/AddCFL/AddCFL"));
const CategoryPage = lazy(() =>
  import("./containers/CategoryPage/CategoryPage")
);
const VideoCategory = lazy(() =>
  import("./containers/VideoCategory/VideoCategory")
);
const AboutUsPage = lazy(() => import("./containers/AboutUsPage/AboutUsPage"));
const ContactUsPage = lazy(() =>
  import("./containers/ContactUsPage/ContactUsPage")
);

import SchemaAddorUpdate from "./containers/SchemaAddorUpdate/SchemaAddorUpdate";
import CategoryAddorUpdate from "./containers/CategoryAddorUpdate/CategoryAddorUpdate";

// Styles Imports
import "./App.css";
import "./containers/global.css";
import FAQPage from "./containers/FAQPage/FAQPage";
import { useApp } from "./customHooks/App/useApp";
import VideoAddorUpdate from "./containers/VideoAddorUpdate/VideoAddorUpdate";
import UserTransactionsPage from "./containers/UserTransactionsPage/UserTransactionsPage";
import FaqAddorUpdate from "./containers/FaqAddorUpdate/FaqAddorUpdate";
import UserListingPage from "./containers/UserListingPage/UserListingPage";
import DeleteAccount from "./containers/DeleteAccount/DeleteAccount";

function App() {
  const { authData } = useSelector((state) => state.loginSlice);
  const customProps = useApp();

  return (
    <>
      {authData ? (
        <>
          <Router>
            <Layout>
              <Suspense fallback={<FullPageLoading />}>
                <Routes>
                  <Route path="/" exact element={<SchemesPage />} />
                  <Route path="/schemes" exact element={<SchemesPage />} />
                  <Route
                    path="/addscheme"
                    exact
                    element={<SchemaAddorUpdate />}
                  />
                  <Route
                    path="/scheme/:id"
                    exact
                    element={<SchemaAddorUpdate />}
                  />

                  {/* <Route path="/queries" exact element={<QueriesPage />} />
              <Route path="/query/:id" exact element={<EditQuery />} /> */}

                  <Route path="/cfl" exact element={<CFLPage />} />
                  <Route path="/addCfl" exact element={<AddCFL />} />
                  <Route path="/editcfl/:id" exact element={<AddCFL />} />

                  <Route
                    path="/expenses"
                    exact
                    element={<CategoryPage categorytype={"Expense"} />}
                  />
                  <Route
                    path="/addexpense"
                    exact
                    element={<CategoryAddorUpdate />}
                  />
                  <Route
                    path="/expense/:id"
                    exact
                    element={<CategoryAddorUpdate />}
                  />

                  <Route
                    path="/incomes"
                    exact
                    element={<CategoryPage categorytype={"Income"} />}
                  />
                  <Route
                    path="/addincome"
                    exact
                    element={<CategoryAddorUpdate />}
                  />
                  <Route
                    path="/income/:id"
                    exact
                    element={<CategoryAddorUpdate />}
                  />

                  <Route
                    path="/savings"
                    exact
                    element={<CategoryPage categorytype={"Savings"} />}
                  />
                  <Route
                    path="/addsavings"
                    exact
                    element={<CategoryAddorUpdate />}
                  />
                  <Route
                    path="/savings/:id"
                    exact
                    element={<CategoryAddorUpdate />}
                  />

                  <Route
                    path="/goals"
                    exact
                    element={<CategoryPage categorytype={"Goals"} />}
                  />
                  <Route
                    path="/addgoals"
                    exact
                    element={<CategoryAddorUpdate />}
                  />
                  <Route
                    path="/goals/:id"
                    exact
                    element={<CategoryAddorUpdate />}
                  />

                  <Route
                    path="/videos"
                    exact
                    element={<CategoryPage categorytype={"Videos"} />}
                  />
                  <Route
                    path="/addvideos"
                    exact
                    element={<CategoryAddorUpdate />}
                  />
                  <Route
                    path="/videos/:id"
                    exact
                    element={<CategoryAddorUpdate />}
                  />

                  <Route
                    path="/videoCategory/:id"
                    exact
                    element={<VideoCategory />}
                  />

                  <Route
                    path="/addVideo"
                    exact
                    element={<VideoAddorUpdate />}
                  />
                  <Route
                    path="/editVideo/:id"
                    exact
                    element={<VideoAddorUpdate />}
                  />

                  <Route
                    path="/faqs"
                    exact
                    element={<CategoryPage categorytype={"FAQ"} />}
                  />
                  <Route
                    path="/addFAQ"
                    exact
                    element={<CategoryAddorUpdate />}
                  />
                  <Route
                    path="/FAQ/:id"
                    exact
                    element={<CategoryAddorUpdate />}
                  />
                  <Route path="/faqCategory/:id" exact element={<FAQPage />} />
                  <Route
                    path="/addCategoryFaq"
                    exact
                    element={<FaqAddorUpdate />}
                  />
                  <Route
                    path="/editCategoryFaq/:id"
                    exact
                    element={<FaqAddorUpdate />}
                  />

                  <Route path="/about-us" exact element={<AboutUsPage />} />

                  <Route path="/contact-us" exact element={<ContactUsPage />} />

                  {/* <Route path="/users" exact element={<UserListingPage />} /> */}

                  {/* <Route
                path="/userTransaction/:id"
                exact
                element={<UserTransactionsPage />}
              /> */}

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </Layout>
          </Router>
        </>
      ) : (
        <>
          <Router>
            <Routes>
              <Route path="/" exact element={<AuthPage />} />
              <Route path="/delete-account" exact element={<DeleteAccount />} />
            </Routes>
          </Router>
        </>
      )}
    </>
  );
}

export default App;
