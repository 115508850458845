import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { endpoint } from "../../api/endpoint";
import { saveAuthData, saveUserData } from "../../features/login/LoginSlice";
import { setLoader } from "../../features/FullPageLoader/FullPageLoaderSlice";
import { apiHandler } from "../../api";
import { saveLanguageEntriesData } from "../../features/appConfig/appConfigSlice";

async function requestAccessToken(username, password) {
  return fetch(endpoint.API_BASE_URL + endpoint.AUTH_LOGIN, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ sEmail: username, sPassword: password }),
  }).then((data) => data.json());
}

async function requestUserInfo(userId, token) {
  return fetch(endpoint.API_BASE_URL + "users/" + userId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

export const useAuthPage = (props) => {
  const { authData } = useSelector((state) => state.loginSlice);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const handleClose = () => {
    setOpenUpdateAlert(false);
  };

  const validate = () => {
    if (username === "" && password === "") {
      setOpenUpdateAlert(true);
      setResponseMessage("Please enter the Credentials to Login! ");
      return;
    }
    if (username.trim() === "") {
      setOpenUpdateAlert(true);
      setResponseMessage("Please enter the Email Address!");
      return;
    } else if (password.trim() === "") {
      setOpenUpdateAlert(true);
      setResponseMessage("Please enter the Password! ");
      return;
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (username !== "" && password !== "") {
      const response = await requestAccessToken(username, password);
      if (response.status === "success") {
        dispatch(saveAuthData(response.data));
      } else {
        addToast(response.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      validate();
    }
  };
  return {
    setUsername,
    setPassword,
    handleSubmit,
    openUpdateAlert,
    handleClose,
    responseMessage,
  };
};
