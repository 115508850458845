import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../features/FullPageLoader/FullPageLoaderSlice";
import { apiHandler } from "../../api";
import { endpoint } from "../../api/endpoint";
import { useNavigate } from "react-router";
import { useToasts } from "react-toast-notifications";

export const useFaqAddorUpdate = (state) => {
  const { addToast } = useToasts();
  const { languageEntries } = useSelector((state) => state.appConfigSlice);
  const { authData } = useSelector((state) => state.loginSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    languageEntries[0].langCode
  );
  const [currentLanguageIndex, setCurrentLanguagIndex] = useState(0);

  const [id, setID] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [requestObject, setRequestObject] = useState([]);

  const [questionError, setQuestionError] = useState(false);
  const [answerError, setAnswerError] = useState(false);

  const [response, setResponse] = useState("");
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false);

  const [newIndex, setNewIndex] = useState(0);

  const handleClose = () => {
    setOpenUpdateAlert(false);
    if (response) {
      navigate(-1, { replace: true });
    }
    setResponse("");
  };

  useEffect(() => {
    if (response !== "") {
      setOpenUpdateAlert(true);
    }
  }, [response]);

  const getFaqDetails = useCallback(
    async (id) => {
      dispatch(setLoader(true));

      const languageCodes = languageEntries.map(
        (language) => language.langCode
      );

      const updatedRequestObject = [...requestObject];

      for (const langCode of languageCodes) {
        const existingEntryIndex = updatedRequestObject.findIndex(
          (entry) => entry.langCode === langCode
        );

        if (existingEntryIndex === -1) {
          const result = await apiHandler({
            url: endpoint.GET_SINGLE_FAQ + id,
            method: "GET",
            authToken: authData.accessToken,
            refreshToken: authData && authData.refreshToken,
            headers: { langcode: langCode },
            dispatch,
            navigate,
          });

          if (result.data.status === "success") {
            const Faq = result.data.data[0];
            if (!requestObject.length) {
              setID(Faq.iFAQId);
            }

            updatedRequestObject.push({
              langCode: langCode,
              sQuestionLabel: Faq.sQuestionLabel,
              sAnswerLabel: Faq.sAnswerLabel,
            });
          } else {
            console.log("getSchemeDetails : result.data", result.data);
          }
        }
      }
      setQuestion(updatedRequestObject[newIndex].sQuestionLabel);
      setAnswer(updatedRequestObject[newIndex].sAnswerLabel);

      setRequestObject(updatedRequestObject);

      dispatch(setLoader(false));
    },
    [requestObject]
  );

  useEffect(() => {
    if (state && state.faq) {
      let Faq = state.faq;
      getFaqDetails(Faq.iFAQId);
    }
  }, [state]);

  const isValidQuestion = () => {
    if (question.trim() === "") {
      setQuestionError("This is a Required Field! ");
      return false;
    }
    setQuestionError("");
    return true;
  };

  const isValidAnswer = () => {
    if (answer.trim() === "") {
      setAnswerError("This is a Required Field! ");
      return false;
    }
    setAnswerError("");
    return true;
  };

  const validate = () => {
    if (isValidQuestion() && isValidAnswer()) {
      return true;
    } else {
      isValidQuestion();
      isValidAnswer();
    }
    return false;
  };

  const handleNextButton = (newIndex) => {
    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];
    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sQuestionLabel = question;
      updatedRequestObject[existingEntryIndex].sAnswerLabel = answer;
    } else {
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sQuestionLabel: question,
        sAnswerLabel: answer,
      });
    }

    setRequestObject(updatedRequestObject);

    let currentIndex = newIndex;

    const currentIndexData = requestObject.findIndex(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (currentIndexData !== -1) {
      setQuestion(requestObject[currentIndexData].sQuestionLabel);
      setAnswer(requestObject[currentIndexData].sAnswerLabel);
    } else {
      setQuestion("");
      setAnswer("");
    }
    setCurrentLanguageCode(languageEntries[currentIndex].langCode);
    setCurrentLanguagIndex(currentIndex);
  };

  const handleBackButton = (newIndex) => {
    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];
    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sQuestionLabel = question;
      updatedRequestObject[existingEntryIndex].sAnswerLabel = answer;
    } else {
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sQuestionLabel: question,
        sAnswerLabel: answer,
      });
    }

    setRequestObject(updatedRequestObject);
    let currentIndex = newIndex;

    const backData = requestObject.filter(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (backData.length) {
      setQuestion(backData[0].sQuestionLabel);
      setAnswer(backData[0].sAnswerLabel);
    } else {
      setQuestion("");
      setAnswer("");
    }
    setCurrentLanguageCode(languageEntries[currentIndex].langCode);
    setCurrentLanguagIndex(currentIndex);
  };

  const addFaq = async (body) => {
    const result = await apiHandler({
      url: endpoint.ADD_FAQ,
      method: "POST",
      data: body,
      authToken: authData.accessToken,
      refreshToken: authData && authData.refreshToken,
      dispatch,
      navigate,
    });
    return result.data;
  };

  const editFaq = async (body) => {
    const result = await apiHandler({
      url: endpoint.EDIT_FAQ + id,
      method: "PUT",
      data: body,
      authToken: authData.accessToken,
      refreshToken: authData && authData.refreshToken,
      dispatch,
      navigate,
    });
    return result.data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];

    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sQuestionLabel = question;
      updatedRequestObject[existingEntryIndex].sAnswerLabel = answer;
    } else {
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sQuestionLabel: question,
        sAnswerLabel: answer,
      });
    }

    setRequestObject(updatedRequestObject);
    dispatch(setLoader(true));

    let currentIndex = newIndex;

    const currentIndexData = requestObject.findIndex(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (currentIndexData !== -1) {
      setQuestion(requestObject[currentIndexData].sQuestionLabel);
      setAnswer(requestObject[currentIndexData].sAnswerLabel);
    } else {
      setQuestion("");
      setAnswer("");
    }
    const body = {
      iCategoryId: state.FAQCategoryId,
      aFAQData: updatedRequestObject,
    };
    const missingLanguages = languageEntries.filter((lang) => {
      const languageData = updatedRequestObject.find(
        (entry) => entry.langCode === lang.langCode
      );
      return (
        !languageData ||
        !languageData.sQuestionLabel ||
        !languageData.sAnswerLabel
      );
    });

    if (missingLanguages.length > 0) {
      const index = languageEntries.findIndex(
        (item) => item.langCode === missingLanguages[0].langCode
      );

      const expectedLanguageCodes = missingLanguages.map((obj) => obj.sSchema);

      setCurrentLanguagIndex(index);
      setNewIndex(index);
      setQuestion("");
      setAnswer("");
      setCurrentLanguageCode(missingLanguages[0].langCode);
      addToast(
        "Data is missing for the following languages: " +
          expectedLanguageCodes.join(", "),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else {
      if (state && state.faq) {
        const response = await editFaq(body);
        if (response.status === "success") {
          setResponse(response.message);
        } else {
          setResponse(response.message);
        }
      } else {
        const response = await addFaq(body);
        if (response.status === "success") {
          setResponse(response.message);
        } else {
          setResponse(response.message);
        }
      }
    }

    dispatch(setLoader(false));
  };

  const handleLanguages = (newIndex) => {
    setNewIndex(newIndex);
    if (currentLanguageIndex < newIndex) {
      handleNextButton(newIndex);
    } else if (currentLanguageIndex > newIndex) {
      handleBackButton(newIndex);
    }
  };

  const placeholderTitle = `Title (in ${languageEntries[newIndex].sSchema})`;

  const handleBack = (event) => {
    event.preventDefault();
    navigate(-1, { replace: true });
  };

  return {
    handleSubmit,
    languageEntries,
    handleLanguages,
    currentLanguageCode,
    openUpdateAlert,
    handleClose,
    response,
    isValidQuestion,
    question,
    questionError,
    setQuestion,
    answerError,
    isValidAnswer,
    answer,
    setAnswer,
    placeholderTitle,
    handleBack
  };
};
