import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCheckCircle } from "react-icons/ai";
import { setLoader } from "../../features/FullPageLoader/FullPageLoaderSlice";
import { apiHandler } from "../../api";
import { endpoint } from "../../api/endpoint";
import { useLocation, useNavigate } from "react-router";
import AlertModal from "../../components/Alert/AlertModal";
import { useToasts } from "react-toast-notifications";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { IoArrowBackCircle } from "react-icons/io5";

const VIDEO_REGEX =
  /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/|playlist\?list=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

const VideoAddorUpdate = () => {
  const { state } = useLocation();
  const { languageEntries } = useSelector((state) => state.appConfigSlice);
  const { addToast } = useToasts();
  const { authData } = useSelector((state) => state.loginSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    languageEntries[0].langCode
  );
  const [currentLanguageIndex, setCurrentLanguagIndex] = useState(0);

  const [tabChanged, setTabChanged] = useState(1);

  const [enabled, setEnabled] = useState(true);
  const [id, setID] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState("");

  const [requestObject, setRequestObject] = useState([]);

  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [videoError, setVideoError] = useState(false);

  const [response, setResponse] = useState("");
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false);

  const [newIndex, setNewIndex] = useState(0);

  const handleClose = () => {
    setOpenUpdateAlert(false);
    if (response) {
      navigate(-1, { replace: true });
    }
    setResponse("");
  };

  useEffect(() => {
    if (response !== "") {
      setOpenUpdateAlert(true);
    }
  }, [response]);

  const getVideoDetails = useCallback(
    async (id) => {
      dispatch(setLoader(true));

      const languageCodes = languageEntries.map(
        (language) => language.langCode
      );

      const updatedRequestObject = [...requestObject];

      for (const langCode of languageCodes) {
        const existingEntryIndex = updatedRequestObject.findIndex(
          (entry) => entry.langCode === langCode
        );
        if (existingEntryIndex === -1) {
          const result = await apiHandler({
            url: endpoint.GETSINGLEVIDEO + id,
            method: "GET",
            authToken: authData.accessToken,
            refreshToken: authData && authData.refreshToken,
            headers: { langcode: langCode },
            dispatch,
            navigate,
          });
          if (result.data.status === "success") {
            const video = result.data.data[0];

            if (!requestObject.length) {
              setEnabled(video.bEnabled === 1);
              setID(video.iVideoId);
              setVideo(video.sVideoURL);
            }

            updatedRequestObject.push({
              langCode: langCode,
              sVideoTitle: video.sVideoTitle,
              sVideoContent: video.sVideoContent,
            });
          } else {
            console.log("getVideoDetails : result.data", result.data);
          }
        }
      }
      setName(updatedRequestObject[newIndex].sVideoTitle);
      setDescription(updatedRequestObject[newIndex].sVideoContent);

      setRequestObject(updatedRequestObject);

      dispatch(setLoader(false));
    },
    [requestObject]
  );

  useEffect(() => {
    if (state && state.video) {
      let video = state.video;
      getVideoDetails(video.iVideoId);
    }
  }, [state]);

  const isValidName = () => {
    if (name.trim() === "") {
      setNameError("This is a Required Field! ");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const isValidDescription = () => {
    if (description.trim() === "") {
      setDescriptionError("This is a Required Field! ");
      return false;
    }
    setDescriptionError("");
    return true;
  };
  const isValidVideo = () => {
    if (video.trim() === "") {
      setVideoError("This is a Required Field! ");
      return false;
    } else if (!VIDEO_REGEX.test(video)) {
      setVideoError("Please enter valid Video Url");
      return false;
    } else {
      setVideoError("");
      return true;
    }
  };

  const validate = () => {
    if (isValidName() && isValidDescription() && isValidVideo()) {
      return true;
    } else {
      isValidName();
      isValidDescription();
      isValidVideo();
    }
    return false;
  };

  const handleNextButton = (newIndex) => {
    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];
    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sVideoTitle = name;
      updatedRequestObject[existingEntryIndex].sVideoContent = description;
    } else {
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sVideoTitle: name,
        sVideoContent: description,
      });
    }

    setRequestObject(updatedRequestObject);

    let currentIndex = newIndex;

    const currentIndexData = requestObject.findIndex(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (currentIndexData !== -1) {
      setName(requestObject[currentIndexData].sVideoTitle);
      setDescription(requestObject[currentIndexData].sVideoContent);
    } else {
      setName("");
      setDescription("");
    }
    setTabChanged(tabChanged + 1);
    setCurrentLanguageCode(languageEntries[currentIndex].langCode);
    setCurrentLanguagIndex(currentIndex);
  };

  const handleBackButton = (newIndex) => {
    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];
    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sVideoTitle = name;
      updatedRequestObject[existingEntryIndex].sVideoContent = description;
    } else {
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sVideoTitle: name,
        sVideoContent: description,
      });
    }

    setRequestObject(updatedRequestObject);

    let currentIndex = newIndex;

    const backData = requestObject.filter(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (backData.length) {
      setName(backData[0].sVideoTitle);
      setDescription(backData[0].sVideoContent);
    } else {
      setName("");
      setDescription("");
    }
    setTabChanged(tabChanged + 1);
    setCurrentLanguageCode(languageEntries[currentIndex].langCode);
    setCurrentLanguagIndex(currentIndex);
  };

  const addVideo = async (body) => {
    const result = await apiHandler({
      url: endpoint.CREATEVIDEOINCATEGORIES,
      method: "POST",
      data: body,
      authToken: authData.accessToken,
      refreshToken: authData && authData.refreshToken,
      dispatch,
      navigate,
    });
    return result.data;
  };

  const editVideo = async (body, iVideoId) => {
    const result = await apiHandler({
      url: endpoint.EDIT_VIDEO + iVideoId,
      method: "PUT",
      data: body,
      authToken: authData.accessToken,
      refreshToken: authData && authData.refreshToken,
      dispatch,
      navigate,
    });
    return result.data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];

    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sVideoTitle = name;
      updatedRequestObject[existingEntryIndex].sVideoContent = description;
    } else {
      // If it doesn't exist, add a new entry to the updatedRequestObject
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sVideoTitle: name,
        sVideoContent: description,
      });
    }

    setRequestObject(updatedRequestObject);
    dispatch(setLoader(true));

    let currentIndex = newIndex;

    const currentIndexData = requestObject.findIndex(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (currentIndexData !== -1) {
      setName(requestObject[currentIndexData].sVideoTitle);
      setDescription(requestObject[currentIndexData].sVideoContent);
    } else {
      setName("");
      setDescription("");
    }

    let body = {
      sVideoURL: video,
      aVideoData: updatedRequestObject,
      iCategoryId: state.videoCategoryId,
      bEnabled: enabled ? 1 : 0,
    };

    const missingLanguages = languageEntries.filter((lang) => {
      const languageData = updatedRequestObject.find(
        (entry) => entry.langCode === lang.langCode
      );
      return (
        !languageData ||
        !languageData.sVideoTitle ||
        !languageData.sVideoContent
      );
    });

    if (missingLanguages.length > 0) {
      const index = languageEntries.findIndex(
        (item) => item.langCode === missingLanguages[0].langCode
      );

      const expectedLanguageCodes = missingLanguages.map((obj) => obj.sSchema);

      setTabChanged(tabChanged + 1);
      setCurrentLanguagIndex(index);
      setNewIndex(index);
      setName("");
      setDescription("");
      setCurrentLanguageCode(missingLanguages[0].langCode);
      addToast(
        "Data is missing for the following languages: " +
          expectedLanguageCodes.join(", "),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else {
      if (state && state.video) {
        const response = await editVideo(body, state.video.iVideoId);
        if (response.status === "success") {
          setResponse(response.message);
        } else {
          setResponse(response.message);
        }
      } else {
        const response = await addVideo(body);
        if (response.status === "success") {
          setResponse(response.message);
        } else {
          setResponse(response.message);
        }
      }
    }

    dispatch(setLoader(false));
  };

  const handleLanguages = (newIndex) => {
    setNewIndex(newIndex);
    if (currentLanguageIndex < newIndex) {
      handleNextButton(newIndex);
    } else if (currentLanguageIndex > newIndex) {
      handleBackButton(newIndex);
    }
  };

  const placeholderTitle = `Title (in ${languageEntries[newIndex].sSchema})`;

  const handleBack = (event) => {
    event.preventDefault();
    navigate(-1, { replace: true });
  };

  return (
    <>
      <div className="InnerContent formfeilds">
        <div className="Row d-flex">
          <div className="col-md-11">
            <label className="Label" style={{ color: "#a75503" }}>
              {state && state.video ? "Edit / Update" : "Add New Video"}
            </label>
          </div>
          <div className="col-md-1">
            <button className="btn btn-primary" onClick={handleBack}>
              <IoArrowBackCircle size={30} />
            </button>
          </div>
        </div>
        <hr className="mt-3 mb-5" />
        <form onSubmit={handleSubmit}>
          <div>
            <div className="form-group formfeilds">
              <div className="row">
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="enable">Enable Category</label>
                    </div>
                    <div className="col-md-12">
                      <span
                        onClick={(e) => setEnabled(!enabled)}
                        className="toggleButton pointer"
                      >
                        {enabled ? (
                          <>
                            <BsToggleOn size={35} color={"Green"} />{" "}
                            <span className="color_green">ON</span>
                          </>
                        ) : (
                          <>
                            <BsToggleOff size={35} color={"Red"} />{" "}
                            <span className="color_red">OFF</span>
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Banner">
                        Video<span style={{ color: "red" }}> *</span>
                      </label>
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="FormControl"
                        onChange={(e) => setVideo(e.target.value)}
                        placeholder="Video URL"
                        onBlur={isValidVideo}
                        value={video}
                      />
                      <div className="row">
                        <div className="text-left text-small col-md-8 color_red">
                          {videoError}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="languagefeilds">
              <ul className="editlanguageButton">
                {languageEntries.map((language, index) => (
                  <li
                    onClick={() => {
                      handleLanguages(index);
                    }}
                    className={
                      currentLanguageCode === language.langCode
                        ? "active"
                        : null
                    }
                  >
                    {currentLanguageCode === language.langCode ? (
                      <AiFillCheckCircle />
                    ) : null}
                    {language.sSchema}{" "}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="tabsGroup ">
            <div className="form-group formfeilds">
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="englishName">
                    Title
                    <span style={{ color: "red" }}> *</span>
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    className="FormControl"
                    onChange={(e) => setName(e.target.value)}
                    placeholder={placeholderTitle}
                    maxLength={50}
                    onBlur={isValidName}
                    value={name}
                  />
                  <div className="row">
                    <div className="text-left text-small col-md-8 color_red">
                      {nameError}
                    </div>
                    <div className="text-right text-small col-md-4">
                      Max limit: 50
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group formfeilds">
              <div className="row">
                <div className="col-md-3">
                  <label htmlFor="Description">
                    Content
                    <span style={{ color: "red" }}> *</span>
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    className="FormControl"
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Content"
                    onBlur={isValidDescription}
                    value={description}
                  />
                  <div className="row">
                    <div className="text-left text-small col-md-8 color_red">
                      {descriptionError}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-primary ml-4" type="submit">
            Save
          </button>
        </form>
      </div>
      <AlertModal
        open={openUpdateAlert}
        handleClose={handleClose}
        handleOk={handleClose}
        title={response}
        showCancel={false}
      />
    </>
  );
};

export default VideoAddorUpdate;
