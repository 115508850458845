import { createSlice } from "@reduxjs/toolkit";

export const fullPageLoaderSlice = createSlice({
  name: "fullPageLoader",
  initialState: {
    loading: false,
  },
  reducers: {
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoader } = fullPageLoaderSlice.actions;

export default fullPageLoaderSlice.reducer;
