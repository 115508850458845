import React from "react";
import { useDeleteAccount } from "../../customHooks/DeleteAccount/useDeleteAccount";
import "./DeleteAccount.css";
import logo from "../../assets/images/logo.png";
import { Loader as LoaderIcon } from "react-feather";
import AlertModal from "../../components/Alert/AlertModal";

const DeleteAccount = () => {
  const talonProps = useDeleteAccount();
  const {
    phone,
    otp,
    setPhone,
    setOtp,
    phoneError,
    otpError,
    isValidPhone,
    isValidOtp,
    sendOTP,
    VerifyOtp,
    verifyOtpModal,
    handleModal,
    openAlert,
    handleClose,
    deleteAccountSuccess,
    setVerifyOtpModal,
    handleDeleteAccount,
    confirmModal,
    successMessage,
    setOtpError,
    setAccessToken,
    setConfirmModal,
    loading,
  } = talonProps;

  return (
    <>
      <div className="delete-account">
        <div className="Container">
          <div className="row d-flex align-items-center  justify-content-center formbox">
            <div className="col-md-6 col-lg-12">
              <div className="imgcontainer">
                <img src={logo} alt="Logo" className="avatar" />
                <h2 className="head">Delete my account</h2>
              </div>
            </div>
            <div className="col-md-6 col-lg-12">
              <center>
                {deleteAccountSuccess ? (
                  <>
                    <div className="content">
                      <h4>{successMessage}</h4>
                      <h2>Thank you !</h2>
                    </div>
                  </>
                ) : !verifyOtpModal ? (
                  <form onSubmit={sendOTP}>
                    <div className="content">
                      <label>
                        <b>Enter your phone number</b>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your phone number"
                        name="phone"
                        onBlur={isValidPhone}
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                      />

                      <div className={`text-left text-small text-center error`}>
                        {phoneError}
                      </div>
                      {loading ? (
                        <button disabled={loading} style={{ width: "100px" }}>
                          <LoaderIcon size={20} className="indicator" />
                        </button>
                      ) : (
                        <button disabled={loading} type="submit">
                          Verify your phone number
                        </button>
                      )}
                    </div>
                  </form>
                ) : (
                  <>
                    {confirmModal ? (
                      <>
                        <form onSubmit={handleModal}>
                          <div className="content">
                            <h4>
                              All your personal data associated with this phone
                              number {""}
                              <b>{phone}</b> {""}
                              will be deleted permanently. Do you want to
                              proceed?
                            </h4>

                            <div className="delete-button">
                              <button
                                onClick={(e) => {
                                  setVerifyOtpModal(false);
                                  setOtpError("");
                                  setOtp("");
                                  setAccessToken("");
                                  setPhone("");
                                  setConfirmModal(false);
                                }}
                                type="button"
                                className="cancelbtn"
                              >
                                Cancel
                              </button>
                              <button type="submit">Proceed to delete</button>
                            </div>
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <form onSubmit={VerifyOtp}>
                          <div className="content">
                            <label>
                              <b>Enter your otp</b>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter your otp"
                              name="OTP"
                              onBlur={isValidOtp}
                              onChange={(e) => setOtp(e.target.value)}
                              value={otp}
                              maxLength={6}
                            />
                            <div className="row">
                              <div
                                className={`text-left text-small text-center error`}
                              >
                                {otpError}
                              </div>
                            </div>
                            <button
                              onClick={(e) => {
                                setVerifyOtpModal(false);
                                setOtpError("");
                                setOtp("");
                              }}
                              type="button"
                              className="cancelbtn"
                            >
                              Cancel
                            </button>

                            {loading ? (
                              <button
                                disabled={loading}
                                style={{ width: "100px" }}
                              >
                                <LoaderIcon size={20} className="indicator" />
                              </button>
                            ) : (
                              <button disabled={loading} type="submit">
                                Verify otp
                              </button>
                            )}
                          </div>
                        </form>
                      </>
                    )}
                  </>
                )}
              </center>
            </div>
          </div>
        </div>
        <AlertModal
          open={openAlert}
          loading={loading}
          handleClose={handleClose}
          handleOk={handleDeleteAccount}
          title={"Are you sure you want to delete your account ?"}
          showCancel={handleClose}
        />
      </div>
    </>
  );
};

export default DeleteAccount;
