import React from "react";
import "../../containers/global.css";
import { Loader as LoaderIcon } from "react-feather";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AiFillAlert } from "react-icons/ai";

export default function AlertModal(props) {
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle className="newpopupstyle">Swadhaar Says...</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <AiFillAlert />
          </DialogContentText>
          <DialogContentText>{props.title}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.loading ? (
            <Button disabled={props.loading} color="success">
              <LoaderIcon size={20} className="indicator" />
            </Button>
          ) : (
            <Button
              disabled={props.loading}
              onClick={props.handleOk}
              color="success"
            >
              Ok
            </Button>
          )}

          {props.showCancel && (
            <Button onClick={props.handleClose} color="success">
              Cancel
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
