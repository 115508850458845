import { createSlice } from "@reduxjs/toolkit";

export const appConfigSlice = createSlice({
  name: "appConfig",
  initialState: {
    languageEntries: [],
  },
  reducers: {
    saveLanguageEntriesData: (state, action) => {
      state.languageEntries = action.payload;
    },
  },
});

export const { saveLanguageEntriesData } = appConfigSlice.actions;

export default appConfigSlice.reducer;
