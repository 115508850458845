import { combineReducers } from "redux";
import fullPageLoaderReducer from "../features/FullPageLoader/FullPageLoaderSlice";
import loginSliceReducer from "../features/login/LoginSlice";
import appConfigReducer from "../features/appConfig/appConfigSlice";

export default combineReducers({
  loginSlice: loginSliceReducer,
  fullPageLoaderSlice: fullPageLoaderReducer,
  appConfigSlice: appConfigReducer,
});
