import React from "react";
import Pagination from "react-js-pagination";

const CustomPagination = ({
  currentPage,
  itemsPerPage,
  itemsCount,
  onPageChange,
}) => {
  return (
    <Pagination
      activePage={currentPage}
      itemsCountPerPage={itemsPerPage}
      totalItemsCount={itemsCount}
      onChange={onPageChange}
      pageRangeDisplayed={5}
      nextPageText={"Next"}
      prevPageText={"Prev"}
      firstPageText={"First"}
      lastPageText={"Last"}
      itemClass="page-item"
      linkClass="page-link"
    />
  );
};

export default CustomPagination;
