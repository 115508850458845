import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { endpoint } from "../../api/endpoint";
import { setLoader } from "../../features/FullPageLoader/FullPageLoaderSlice";
import { apiHandler } from "../../api";
import { saveLanguageEntriesData } from "../../features/appConfig/appConfigSlice";

const languages = [
  {
    iLanguageId: 1,
    langCode: "en",
    sSchema: "English",
  },
  {
    iLanguageId: 2,
    langCode: "hn",
    sSchema: "Hindi",
  },
];

export const useApp = (props) => {
  const { authData } = useSelector((state) => state.loginSlice);
  const dispatch = useDispatch();
  const updateLanguageEntries = async () => {
    // dispatch(setLoader(true));
    // const result = await apiHandler({
    //   url: endpoint.GET_API_CONFIG,
    //   method: "GET",
    //   authToken: authData.accessToken,
    //   dispatch,
    // });
    // if (result.data.status === "success") {
    dispatch(saveLanguageEntriesData(languages));
    // } else {
    //   console.log("updateLanguageEntries : result.data", result.data);
    // }
    // dispatch(setLoader(false));
  };
  useEffect(async () => {
    if (authData && authData.accessToken) {
      await updateLanguageEntries();
    }
  }, [authData]);
  return {
    authData,
  };
};
