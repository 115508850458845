import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import AlertModal from "../Alert/AlertModal";
import { TbTrash } from "react-icons/tb";

const FAQItem = (props) => {
  const { faq, onDelete, FAQCategoryId } = props;
  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = () => {
    onDelete(faq.iFAQId);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleOk = () => {
    setOpenAlert(false);
    handleDelete();
  };
  const confirmDelete = () => {
    setOpenAlert(true);
  };
  return (
    <>
      <tr>
        <td>{faq.sQuestionLabel}</td>
        <td>{faq.sAnswerLabel}</td>

        <td>
          {" "}
          <span>
            <Link
              to={`/editCategoryFaq/${faq.iFAQId}`}
              state={{ faq: faq, FAQCategoryId }}
            >
              <AiOutlineEdit size={25} />
            </Link>
          </span>
        </td>
        <td>
          <span onClick={confirmDelete} className="TrashBtn">
            <TbTrash size={25} />
          </span>
        </td>
      </tr>
      <AlertModal
        open={openAlert}
        handleClose={handleClose}
        handleOk={handleOk}
        title={`Are you sure you want to delete FAQ?`}
        showCancel={true}
      />
    </>
  );
};

export default FAQItem;
