import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { createGlobalStyle, ThemeProvider } from "styled-components";

// import Header from "./Header/Header";
// import Footer from "./Footer/footer";
import NavBar from "../../components/NavBar/NavBar";

// Styles Import
import styles from "./index.module.css";
import FullPageLoading from "../../components/FullPageLoading/FullPageLoading";

const Layout = ({ children }) => {
  const { loading } = useSelector((state) => state.fullPageLoaderSlice);
  return (
    <>
      <ThemeProvider theme={{ fontFamily: "Poppins" }}>
        <NavBar />
        {/* <Header /> */}
        <main className={styles.main}>
          {children}
          {loading ? <FullPageLoading /> : null}
        </main>
        {/* <Footer /> */}
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Layout;
