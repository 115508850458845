import React from "react";
import { Link, useParams } from "react-router-dom";
import FAQItem from "../../components/FAQItem/FAQItem";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { useFAQPage } from "../../customHooks/FAQPage/useFAQPage";
import LanguageBar from "../../components/LanguageBar/LanguageBar";
import { IoArrowBackCircle } from "react-icons/io5";

const FAQPage = () => {
  const { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const hookProps = useFAQPage({ FAQCategoryId: id, urlParams });
  const {
    languageEntries,
    langCode,
    setLangCode,
    FAQData,
    deleteFAQ,
    currentPage,
    itemsPerPage,
    setItemsPerPage,
    totalItemsCount,
    onPageChange,
    handleBack,
  } = hookProps;

  return (
    <>
      <div className="InnerContent formfeilds">
        <div className="row">
          <div className="col-md-6">
            <label className="Label" style={{ color: "#a75503" }}>FAQ'S</label>
          </div>
          <div className="col-md-6 text-right">
            <Link
              className="UploadButton"
              state={{ FAQCategoryId: id }}
              to="/addCategoryFaq"
            >
              Add FAQ
            </Link>
          </div>
        </div>
        <hr></hr>
        <div className="formtag mt-5">
          <div className="row">
            <div className="col-9">
              <LanguageBar
                languageEntries={languageEntries}
                langCode={langCode}
                setLangCode={setLangCode}
              />
            </div>
            <div className="col-2">
              {/* {totalItemsCount ? (
                <div
                  className="text-right mr-3 mb-2"
                  style={{ marginTop: "12px !important" }}
                >
                  Results Per Page&nbsp;:&nbsp;
                  <select onChange={(e) => setItemsPerPage(e.target.value)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                </div>
              ) : null} */}
            </div>
            <div className="col-md-1">
              <button className="btn btn-primary" onClick={handleBack}>
                <IoArrowBackCircle size={30} />
              </button>
            </div>
          </div>
          <div>
            <table
              className=" table table-striped table-bordered"
              cellSpacing="0"
              cellPadding="0"
              width="100%"
            >
              <thead className="thead-dark">
                <tr>
                  <th width={"40%"}>Question</th>
                  <th width={"40"}>Answer</th>
                  <th width={"10"}>Edit</th>
                  <th width={`10%`}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {FAQData ? (
                  FAQData.length ? (
                    <>
                      {FAQData.map((faq, index) => (
                        <FAQItem
                          faq={faq}
                          key={index}
                          count={index}
                          onDelete={deleteFAQ}
                          FAQCategoryId={id}
                        />
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="4">
                        No FAQ Found
                      </td>
                    </tr>
                  )
                ) : null}
              </tbody>
            </table>
          </div>

          {/* {itemsPerPage <= totalItemsCount ? (
            <div className="d-flex justify-content-center mt-5">
              <CustomPagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                itemsCount={totalItemsCount}
                onPageChange={onPageChange}
              />
            </div>
          ) : null} */}
        </div>
      </div>
    </>
  );
};

export default FAQPage;
