import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetData } from "../../features/login/LoginSlice";
import { categoryPages } from "../CategoryPage/CategoryTypes";

const CategoryNavs = categoryPages.map((category) => ({
  title: category.title,
  path: category.path,
}));

export const tabs = [
  {
    title: "Schemes",
    path: "/schemes",
  },
  ...CategoryNavs,
  {
    title: "CFL",
    path: "/cfl",
  },
  {
    title: "About Us",
    path: "/about-us",
  },
  {
    title: "Contact Us",
    path: "/contact-us",
  },
  // {
  //   title: "Users",
  //   path: "/users",
  // },
  {
    title: "Logout",
    path: "/",
  },
];

export const useNavbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);

  const index = tabs.findIndex((obj) => obj.path === location.pathname);

  useEffect(() => {
    const currentIndex = localStorage.getItem("tabIndex");
    if (currentIndex > 0) {
      localStorage.setItem("tabIndex", index);
    } else {
      localStorage.setItem("tabIndex", 0);
    }
  }, [location]);

  const handleLogout = () => {
    setOpenAlert(false);
    dispatch(resetData());
    navigate("/", { replace: true });
  };

  const handleClose = () => {
    setOpenAlert(false);
  };
  const handleOpen = () => {
    setOpenAlert(true);
  };

  const handleItemClick = (index) => {
    localStorage.setItem("tabIndex", index);
  };

  return {
    openAlert,
    handleClose,
    handleLogout,
    handleOpen,
    handleItemClick,
  };
};
