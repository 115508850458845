import { AiOutlineCheck } from "react-icons/ai";

import React from "react";
import "./languageBar.css"

const LanguageBar = (props) => {
  const { languageEntries, langCode, setLangCode } = props;
  return (
    <><ul className="newbutton">
      {languageEntries.length
        ? languageEntries.map((language, index) => (
            <li className={language.langCode === langCode ?"active" : "nonactive"} onClick={() => setLangCode(language.langCode)} key={index}>
              {" "}
              {language.langCode === langCode ? <AiOutlineCheck /> : null} &nbsp;
              {language.sSchema}{" "}
            </li>
          ))
        : null}
        </ul>
    </>
  );
};

export default LanguageBar;
