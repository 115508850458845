import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  convertToRaw,
  convertFromHTML,
  EditorState,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";

import "./HtmlEditor.css";

const toolbarOptions = {
  options: [
    "inline",
    "blockType",
    "list",
    "textAlign",
    "colorPicker",
    "fontSize",
    "link",
  ],
  inline: {
    options: ["bold", "italic", "underline"],
  },
  blockType: {
    options: ["Normal", "H3", "H4", "Blockquote"],
  },
  list: {
    options: ["unordered", "ordered"],
  },
  textAlign: {
    options: ["left", "center", "right"],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
  },
};

const HtmlEditor = (props) => {
  const {
    content,
    setContent,
    tabchanged = 1,
    setEditorState,
    editorState,
  } = props;
  const [htmlView, setHtmlView] = useState(true);
  const [editorChange, setEditorChange] = useState(false);

  const initialContentUpdated = useRef();

  useEffect(() => {
    if (tabchanged) {
      initialContentUpdated.current = false;
    }
  }, [tabchanged]);

  useEffect(() => {
    if (content && !initialContentUpdated.current) {
      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(content)
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [content, initialContentUpdated]);

  const handleEditorChange = (editorContent) => {
    setEditorState(editorContent);
    initialContentUpdated.current = true;

    setEditorChange(true);
    if (editorContent.getCurrentContent().hasText()) {
      const HtmlConvertData = draftToHtml(
        convertToRaw(editorContent.getCurrentContent())
      );
      setContent(HtmlConvertData);
    } else {
      setContent("");
    }
  };
  const updatehtmlContent = (e) => {
    initialContentUpdated.current = false;
    setContent(e.target.value);
  };

  return (
    <div className="App my-3">
      <span onClick={() => setHtmlView(!htmlView)} className="htmlViewBtn">
        {htmlView ? "Edit Html" : "Html View"}
      </span>
      <div className="my-3">
        {!htmlView ? (
          <Editor
            editorState={editorState}
            defaultEditorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={toolbarOptions}
          />
        ) : (
          <div className="code-view">
            <textarea
              className="text-area"
              onChange={updatehtmlContent}
              value={content}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HtmlEditor;
