import React from "react";
import MainLogo from "../../assets/images/logo.png";
import Mainescreen from "../../assets/images/animationscreen.png";
import BackgroundLogo from "../../assets/images/loginbg.jpg";
import AlertModal from "../../components/Alert/AlertModal";
import { useAuthPage } from "../../customHooks/AuthPage/useAuthPage";

import "./AuthPage.css";

export default function AuthPage() {
  const hookProps = useAuthPage();
  const {
    setUsername,
    setPassword,
    handleSubmit,
    openUpdateAlert,
    handleClose,
    responseMessage,
  } = hookProps;
    return (
        <div className="LoginPageWrapper" style={{ "background": `url(${BackgroundLogo})`}}>
          <div className="Row">
              <div className="Colmd8">
                  <div className="maineformbox">
                        <div className="newloginBox">
                            <div className="maineweblogo"><img src={MainLogo} alt="Logo" /></div>
                          <h3 className="LoginHeading">Welcome!</h3>
                          <small>Continue to Login with Email and Password!</small>
                          <div className="Mt4 frontlogin">
                              <form onSubmit={handleSubmit}>
                                  <div className="FormLabel">
                                      <input
                                          type="text"
                                          className="FormControl"
                                          onChange={(e) => setUsername(e.target.value)}
                                          placeholder="Email address"
                                      />
                                  </div>
                                  <div className="FormLabel">
                                      <input
                                          className="FormControl"
                                          type="password"
                                          onChange={(e) => setPassword(e.target.value)}
                                          placeholder="Password"
                                      />
                                  </div>
                                  <div>
                                      <button className="SignInButton" type="submit">
                                          Sign In
                                      </button>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="Colmd4">
                  <div className="rightsideBox">
                        <img src={Mainescreen} alt="Main Splash Screen" />
                  </div>
              </div>
          </div>

      
      <AlertModal
        open={openUpdateAlert}
        handleClose={handleClose}
        handleOk={handleClose}
        title={responseMessage}
      />
    </div>
  );
}
