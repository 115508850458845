import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { useFaqAddorUpdate } from "../../customHooks/useFaqAddorUpdate/useFaqAddorUpdate";
const AlertModal = React.lazy(() =>
  import("../../components/Alert/AlertModal")
);
import { useLocation } from "react-router";
import { IoArrowBackCircle } from "react-icons/io5";

const FaqAddorUpdate = () => {
  const { state } = useLocation();
  const hookProps = useFaqAddorUpdate(state);
  const {
    handleSubmit,
    languageEntries,
    handleLanguages,
    currentLanguageCode,
    openUpdateAlert,
    handleClose,
    response,
    isValidQuestion,
    question,
    questionError,
    setQuestion,
    answerError,
    isValidAnswer,
    answer,
    setAnswer,
    placeholderTitle,
    handleBack,
  } = hookProps;

  return (
    <>
      <div className="InnerContent formfeilds">
        <div className="Row d-flex">
          <div className="col-md-11">
            <label className="Label" style={{ color: "#a75503" }}>
              {state && state.faq ? "Edit FAQ" : "Add New FAQ"}
            </label>
          </div>
          <div className="col-md-1">
            <button className="btn btn-primary" onClick={handleBack}>
              <IoArrowBackCircle size={30} />
            </button>
          </div>
        </div>
        <hr className="mt-3 mb-5" />
        <form onSubmit={handleSubmit}>
          <div>
            <div className="languagefeilds" style={{ border: "none" }}>
              <ul className="editlanguageButton">
                {languageEntries.map((language, index) => (
                  <li
                    onClick={() => {
                      handleLanguages(index);
                    }}
                    className={
                      currentLanguageCode === language.langCode
                        ? "active"
                        : null
                    }
                  >
                    {currentLanguageCode === language.langCode ? (
                      <AiFillCheckCircle />
                    ) : null}
                    {language.sSchema}
                  </li>
                ))}
              </ul>
            </div>

            <div className="tabsGroup">
              <div className="form-group formfeilds">
                <div className="row">
                  <div className="col-md-2">
                    <label htmlFor="englishQuestion">
                      Question
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="FormControl"
                      onChange={(e) => setQuestion(e.target.value)}
                      placeholder={placeholderTitle}
                      onBlur={isValidQuestion}
                      value={question}
                    />
                    <div className="row">
                      <div className="text-left text-small col-md-8 color_red">
                        {questionError}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group formfeilds">
                <div className="row">
                  <div className="col-md-2">
                    <label htmlFor="englishAnswer">
                      Answer
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="FormControl"
                      onChange={(e) => setAnswer(e.target.value)}
                      placeholder={placeholderTitle}
                      onBlur={isValidAnswer}
                      value={answer}
                    />
                    <div className="row">
                      <div className="text-left text-small col-md-8 color_red">
                        {answerError}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button className="btn btn-primary ml-4" type="submit">
            Save
          </button>
        </form>
      </div>
      <AlertModal
        open={openUpdateAlert}
        handleClose={handleClose}
        handleOk={handleClose}
        title={response}
        showCancel={false}
      />
    </>
  );
};

export default FaqAddorUpdate;
