import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { setLoader } from "../../features/FullPageLoader/FullPageLoaderSlice";
import { apiHandler } from "../../api";
import { endpoint } from "../../api/endpoint";
import { useLocation, useNavigate } from "react-router";
import AlertModal from "../../components/Alert/AlertModal";
import { useToasts } from "react-toast-notifications";
import { Icon } from "@iconify/react";
import { IoArrowBackCircle } from "react-icons/io5";

const TITLE_REGEX = /^[\u0900-\u097F A-Za-z\s'&-]+$/;
const colorCodeRegex = /^#([A-Fa-f0-9]{6})$/;

const CategoryAddorUpdate = () => {
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const { state } = useLocation();
  const { addToast } = useToasts();
  const { languageEntries } = useSelector((state) => state.appConfigSlice);
  const { authData } = useSelector((state) => state.loginSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    languageEntries[0].langCode
  );
  const [currentLanguageIndex, setCurrentLanguagIndex] = useState(0);

  const [enabled, setEnabled] = useState(true);
  const [id, setID] = useState("");
  const [name, setName] = useState("");
  const [color, setColor] = useState(null);
  const [bannerImage, setBannerImage] = useState("initial");
  const [bannerShow, setBannerShow] = useState(null);
  const [smallBannerImage, setSmallBannerImage] = useState("initial");
  const [smallBannerShow, setSmallBannerShow] = useState(null);
  const [requestObject, setRequestObject] = useState([]);

  const [nameError, setNameError] = useState(false);
  const [colorError, setColorError] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const [smallBannerError, setSmallBannerError] = useState(false);

  const [response, setResponse] = useState("");
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false);

  const [newIndex, setNewIndex] = useState(0);

  const handleClose = () => {
    setOpenUpdateAlert(false);
    if (response) {
      navigate(-1, { replace: true });
    }
    setResponse("");
  };

  useEffect(() => {
    if (response !== "") {
      setOpenUpdateAlert(true);
    }
  }, [response]);

  const getCategoryDetails = useCallback(
    async (id) => {
      dispatch(setLoader(true));
      const languageCodes = languageEntries.map(
        (language) => language.langCode
      );

      const updatedRequestObject = [...requestObject];

      for (const langCode of languageCodes) {
        const existingEntryIndex = updatedRequestObject.findIndex(
          (entry) => entry.langCode === langCode
        );
        if (existingEntryIndex === -1) {
          const result = await apiHandler({
            url: endpoint.GETSINGLECATEGORY + id,
            method: "GET",
            authToken: authData.accessToken,
            refreshToken: authData && authData.refreshToken,
            headers: { langcode: langCode },
            dispatch,
            navigate,
          });
          if (result.data.status === "success") {
            const category = result.data.data[0];
            if (!requestObject.length) {
              setEnabled(category.bEnabled === 1);
              setID(category.iCategoryId);
              setBannerImage(category.sCategoryIcon);
              setBannerShow(category.sCategoryIcon);
              setSmallBannerImage(category.sSmallIcon);
              setSmallBannerShow(category.sSmallIcon);
              setColor(category.sColorCode);
            }

            updatedRequestObject.push({
              langCode: langCode,
              sCategoryLabel: category.sCategoryLabel,
            });
          } else {
            console.log("getCategoryDetails : result.data", result.data);
          }
        }
      }
      setName(updatedRequestObject[newIndex].sCategoryLabel);
      setRequestObject(updatedRequestObject);

      dispatch(setLoader(false));
    },
    [requestObject]
  );
  useEffect(() => {
    if (state && state.category) {
      let category = state.category;
      getCategoryDetails(category.iCategoryId);
    }
  }, [state]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      setBannerShow(null);
      setBannerImage(null);
      inputRef.current.value = null;
      return;
    }

    if (!file.type.startsWith("image/")) {
      addToast("Please upload a valid image file.", {
        appearance: "error",
        autoDismiss: true,
      });
      setBannerShow(null);
      inputRef.current.value = null;
      setBannerImage(null);

      return;
    }

    setBannerImage(file);

    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const maxWidth = 800;
        const maxHeight = 600;

        if (img.width > maxWidth || img.height > maxHeight) {
          addToast(
            `Maximum dimensions should be ${maxWidth}x${maxHeight} or smaller.`,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          setBannerShow(null);
          setBannerImage(null);
          inputRef.current.value = null;
        } else {
          setBannerError("");
          setBannerShow(e.target.result);
        }
      };
    };

    reader.readAsDataURL(file);
  };

  const tempRef = useRef();
  const onBlurFileChange = (event) => {
    const file = event.target.files[0];
    if (!file && tempRef.current) {
      setBannerShow(null);
      setBannerError("This is a Required Field! ");
      return;
    }
    tempRef.current = tempRef + 1;
  };

  const handleSmallIconFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setSmallBannerShow(null);
      inputRef1.current.value = null;
      return;
    }

    if (!file.type.startsWith("image/")) {
      addToast("Please upload a valid image file.", {
        appearance: "error",
        autoDismiss: true,
      });
      setSmallBannerShow(null);
      inputRef1.current.value = null;
      setSmallBannerImage(null);

      return;
    }

    setSmallBannerImage(file);

    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const maxWidth = 300;
        const maxHeight = 300;

        if (img.width > maxWidth || img.height > maxHeight) {
          addToast(
            `Maximum dimensions should be ${maxWidth}x${maxHeight} or smaller.`,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          setSmallBannerShow(null);
          setSmallBannerImage(null);
          inputRef1.current.value = null;
        } else {
          setSmallBannerError("");
          setSmallBannerShow(e.target.result);
        }
      };
    };

    reader.readAsDataURL(file);
  };

  const tempRef1 = useRef();
  const onBlurSmallFileChange = (e) => {
    const file = e.target.files[0];
    if (!file && tempRef1.current) {
      setBannerShow(null);
      setSmallBannerError("This is a Required Field! ");
      return;
    }
    tempRef1.current = tempRef1 + 1;
  };

  const isValidName = () => {
    setNameErrors([]);
    if (name.trim() === "") {
      setNameError("This is a Required Field! ");
      return false;
    } else if (!TITLE_REGEX.test(name)) {
      setNameError("Please enter valid Title");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const isValidColor = () => {
    if (color === null) {
      setColorError("This is a Required Field! ");
      return false;
    } else if (!colorCodeRegex.test(color)) {
      setColorError("Please enter valid Color Code");
      return false;
    } else {
      setColorError("");
      return true;
    }
  };

  const isValidBanner = () => {
    if (bannerShow === null) {
      setBannerError("This is a Required Field! ");
      return false;
    }
    setBannerError("");
    return true;
  };

  const isValidSmallBanner = () => {
    if (smallBannerShow === null) {
      setSmallBannerError("This is a Required Field! ");
      return false;
    }
    setSmallBannerError("");
    return true;
  };

  const [nameErrors, setNameErrors] = useState([]);
  const currentLanguageError = nameErrors.find(
    (error) => error.langCode === currentLanguageCode
  );

  const setDynamicErrors = (item, errorMessage, errors) => {
    return new Promise((resolve) => {
      const existingErrorIndex = errors.findIndex(
        (error) => error.langCode === item.langCode
      );
      if (existingErrorIndex !== -1) {
        // If the error exists, update the error message
        const newErrors = [...errors];
        newErrors[existingErrorIndex].error = errorMessage;
        resolve(newErrors);
      } else {
        // If the error doesn't exist, add a new object to the array
        resolve([...errors, { langCode: item.langCode, error: errorMessage }]);
      }
    });
  };

  const validateDynamicInputs = async () => {
    let state = 0;
    let errors = nameErrors.slice();
    const currentRequestObject = [...requestObject];
    for (const currentItem of currentRequestObject) {
      const item = { ...currentItem };
      if (item.sCategoryLabel.trim() === "") {
        let errorMessage = "This is a Required Field! ";
        errors = await setDynamicErrors(item, errorMessage, errors);
        state += 1;
        // break;
      } else if (!TITLE_REGEX.test(item.sCategoryLabel)) {
        let errorMessage = "Please enter valid Title";
        errors = await setDynamicErrors(item, errorMessage, errors);
        state += 1;
        // break;
      } else {
        let errorMessage = "";
        errors = await setDynamicErrors(item, errorMessage, errors);
      }
    }
    const currentIndexErrorWithMessage = errors.find(
      (error) => error.langCode === currentLanguageCode && error.error !== ""
    );
    const anotherTabErrorWithMessage = errors.find(
      (error) => error.error !== ""
    );
    const firstErrorWithMessage =
      currentIndexErrorWithMessage || anotherTabErrorWithMessage;
    if (firstErrorWithMessage) {
      const { langCode } = firstErrorWithMessage;
      const languageEntry = languageEntries.find(
        (entry) => entry.langCode === langCode
      );

      if (languageEntry) {
        const { langCode: errorlangCode } = languageEntry;
        const languageEntryIndex = languageEntries.findIndex(
          (entry) => entry.langCode === errorlangCode
        );
        handleLanguages(languageEntryIndex);
      }
    } else {
      console.log("No errors with non-empty messages found.");
    }

    setNameErrors(errors);

    return state === 0 ? true : false;
  };

  const validate = () => {
    return new Promise((resolve, reject) => {
      if (state.Type === "Goals") {
        validateDynamicInputs()
          .then((dynamicInputsValid) => {
            if (
              dynamicInputsValid &&
              isValidBanner() &&
              isValidSmallBanner() &&
              isValidColor()
            ) {
              resolve("validated");
            } else {
              isValidBanner();
              isValidColor();
              isValidSmallBanner();
              resolve("notValidated");
            }
          })
          .catch((error) => {
            console.error("Error during dynamic input validation:", error);
            resolve("notValidated");
          });
      } else if (state.Type === "Income" || state.Type === "Expense") {
        validateDynamicInputs()
          .then((dynamicInputsValid) => {
            if (dynamicInputsValid && isValidBanner() && isValidColor()) {
              resolve("validated");
            } else {
              isValidColor();
              isValidBanner();
              resolve("notValidated");
            }
          })
          .catch((error) => {
            console.error("Error during dynamic input validation:", error);
            resolve("notValidated");
          });
      } else {
        validateDynamicInputs()
          .then((dynamicInputsValid) => {
            if (dynamicInputsValid && isValidBanner()) {
              resolve("validated");
            } else {
              isValidBanner();
              resolve("notValidated");
            }
          })
          .catch((error) => {
            console.error("Error during dynamic input validation:", error);
            resolve("notValidated");
          });
      }
    });
  };

  const handleNextButton = (newIndex) => {
    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];
    // If it exists, update sCategoryLabel and sSchemeContent
    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sCategoryLabel = name;
    } else {
      // If it doesn't exist, add a new entry to the updatedRequestObject
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sCategoryLabel: name,
      });
    }
    setRequestObject(updatedRequestObject);

    let currentIndex = newIndex;

    const currentIndexData = requestObject.findIndex(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (currentIndexData !== -1) {
      setName(requestObject[currentIndexData].sCategoryLabel);
    } else {
      setName("");
    }
    setNameError("");
    setCurrentLanguageCode(languageEntries[currentIndex].langCode);
    setCurrentLanguagIndex(currentIndex);
  };

  const handleBackButton = (newIndex) => {
    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];
    // If it exists, update sCategoryLabel and sSchemeContent
    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sCategoryLabel = name;
    } else {
      // If it doesn't exist, add a new entry to the updatedRequestObject
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sCategoryLabel: name,
      });
    }
    setRequestObject(updatedRequestObject);
    let currentIndex = newIndex;

    const backData = requestObject.filter(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (backData.length) {
      setName(backData[0].sCategoryLabel);
    } else {
      setName("");
    }
    setNameError("");
    setCurrentLanguageCode(languageEntries[currentIndex].langCode);
    setCurrentLanguagIndex(currentIndex);
  };

  const addCategory = async (formData, categorytype) => {
    const result = await apiHandler({
      url: endpoint.ADD_CATEGORY + categorytype,
      method: "POST",
      data: formData,
      authToken: authData.accessToken,
      refreshToken: authData && authData.refreshToken,
      dispatch,
      navigate,
    });
    return result.data;
  };

  const editCategory = async (formData, id) => {
    const result = await apiHandler({
      url: endpoint.EDIT_CATEGORY + id,
      method: "PUT",
      data: formData,
      authToken: authData.accessToken,
      refreshToken: authData && authData.refreshToken,
      dispatch,
      navigate,
    });
    return result.data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];

    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sCategoryLabel = name;
    } else {
      // If it doesn't exist, add a new entry to the updatedRequestObject
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sCategoryLabel: name,
      });
    }

    setRequestObject(updatedRequestObject);

    const result = await validate();

    const missingLanguages = languageEntries.filter((lang) => {
      const languageData = updatedRequestObject.find(
        (entry) => entry.langCode === lang.langCode
      );
      return (
        !languageData ||
        !languageData.sCategoryLabel ||
        !TITLE_REGEX.test(updatedRequestObject[newIndex].sCategoryLabel)
      );
    });

    if (missingLanguages.length > 0) {
      const index = languageEntries.findIndex(
        (item) =>
          item.langCode ===
          (missingLanguages.length === 1
            ? missingLanguages[0].langCode
            : missingLanguages[currentLanguageIndex].langCode)
      );

      const expectedLanguageCodes = missingLanguages.map((obj) => obj.sSchema);

      setCurrentLanguagIndex(index);
      setNewIndex(index);
      setCurrentLanguageCode(
        missingLanguages.length === 1
          ? missingLanguages[0].langCode
          : missingLanguages[currentLanguageIndex].langCode
      );
      if (TITLE_REGEX.test(updatedRequestObject[newIndex].sCategoryLabel)) {
        setName("");
        addToast(
          "Data is missing for the following languages: " +
            expectedLanguageCodes.join(", "),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    } else if (result !== "validated") {
      return;
    } else {
      dispatch(setLoader(true));

      let currentIndex = newIndex;

      const currentIndexData = requestObject.findIndex(
        (entry) => entry.langCode === languageEntries[currentIndex].langCode
      );

      if (currentIndexData !== -1) {
        setName(requestObject[currentIndexData].sCategoryLabel);
      } else {
        setName("");
      }

      const formData = new FormData();
      formData.append("bEnabled", enabled ? 1 : 0);
      formData.append("aLabelData", JSON.stringify(updatedRequestObject));
      formData.append("sCategoryIcon", bannerImage);
      formData.append("sColorCode", color);
      formData.append("sSmallIcon", smallBannerImage);

      if (state && state.category) {
        const response = await editCategory(formData, id);
        if (response.status === "success") {
          setResponse(response.message);
        } else {
          setResponse(response.message);
        }
      } else if (state && state.currentPageDetails) {
        const response = await addCategory(
          formData,
          state.currentPageDetails.type
        );
        if (response.status === "success") {
          setResponse(response.message);
        } else {
          setResponse(response.message);
        }
      } else {
        setResponse("Something went wrong");
      }
      dispatch(setLoader(false));
    }
  };
  const handleLanguages = (newIndex) => {
    setNewIndex(newIndex);
    if (currentLanguageIndex < newIndex) {
      handleNextButton(newIndex);
    } else if (currentLanguageIndex > newIndex) {
      handleBackButton(newIndex);
    }
  };

  const placeholderTitle = `Title (in ${languageEntries[newIndex].sSchema})`;

  const handleBack = (event) => {
    event.preventDefault();
    navigate(-1, { replace: true });
  };

  useEffect(() => {
    const hasHindiObject = requestObject.some((obj) => obj.langCode === "hn");
    if (newIndex === 1 && !hasHindiObject) {
      setRequestObject((prevRequestObject) => [
        ...prevRequestObject,
        {
          langCode: "hn",
          sCategoryLabel: "",
        },
      ]);
    }
  }, [requestObject, newIndex]);

  return (
    <>
      <div className="InnerContent formfeilds">
        <div className="Row d-flex">
          <div className="col-md-11">
            <label className="Label" style={{ color: "#a75503" }}>
              {state && state.category
                ? `Edit ${state.category.sCategoryLabel}`
                : state && state.currentPageDetails
                ? `Add Category in ${state.currentPageDetails.title}`
                : "Add New Category"}
            </label>
          </div>
          <div className="col-md-1">
            <button className="btn btn-primary" onClick={handleBack}>
              <IoArrowBackCircle size={30} />
            </button>
          </div>
        </div>
        <hr className="mt-3 mb-5" />
        <form onSubmit={handleSubmit}>
          <div className="form-group formfeilds">
            <div className="row">
              <div className="col-md-2">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="enable">Enable Category</label>
                  </div>
                  <div className="col-md-12">
                    <span
                      onClick={(e) => setEnabled(!enabled)}
                      className="toggleButton pointer"
                    >
                      {enabled ? (
                        <>
                          <BsToggleOn size={35} color={"Green"} />{" "}
                          <span className="color_green">ON</span>
                        </>
                      ) : (
                        <>
                          <BsToggleOff size={35} color={"Red"} />{" "}
                          <span className="color_red">OFF</span>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {state.Type === "Income" || state.Type === "Expense" ? (
                <>
                  <div className="col-2">
                    <div className="form-group formfeilds">
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="englishName">
                            Color Code
                            <span style={{ color: "red" }}> *</span>
                          </label>
                        </div>
                        <div className="col-md-12">
                          <input
                            type="text"
                            className="FormControl"
                            onChange={(e) => setColor(e.target.value)}
                            placeholder="Color Code"
                            maxLength={7}
                            onBlur={isValidColor}
                            value={color}
                          />
                          <div className="text-right text-small col-md-12">
                            Max limit: 7
                          </div>
                          <div className="text-left text-small col-md-12 color_red">
                            {colorError}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {state.Type === "Goals" ? (
                <>
                  <div className="col-2">
                    <div className="form-group formfeilds">
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="englishName">
                            Color Code
                            <span style={{ color: "red" }}> *</span>
                          </label>
                        </div>
                        <div className="col-md-12">
                          <input
                            type="text"
                            className="FormControl"
                            onChange={(e) => setColor(e.target.value)}
                            placeholder="Color Code"
                            maxLength={7}
                            onBlur={isValidColor}
                            value={color}
                          />
                          <div className="text-right text-small col-md-12">
                            Max limit: 7
                          </div>
                          <div className="text-left text-small col-md-12 color_red">
                            {colorError}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group formfeilds">
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="Banner">
                            Small Icon<span style={{ color: "red" }}> *</span>
                          </label>
                        </div>
                        <div className="col-md-12 bannerBox">
                          <input
                            type="file"
                            onChange={handleSmallIconFileChange}
                            onBlur={onBlurSmallFileChange}
                            ref={inputRef1}
                            accept=".jpg, .jpeg, .png, .webp"
                          />
                          <span className="input_info">
                            <Icon
                              icon="material-symbols:info-outline-rounded"
                              fontSize={14}
                            />
                            Supported formats: JPEG/JPG, PNG and WEBP.
                          </span>
                          <div className="row mb-4">
                            <div className="text-left text-small col-md-8 color_red">
                              {smallBannerError}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    {smallBannerShow ? (
                      <div className="my-2 downloadimg">
                        <img
                          className="SchemeBanner "
                          src={smallBannerShow}
                          alt="Selected Banner"
                        />
                      </div>
                    ) : null}
                  </div>
                </>
              ) : null}
              <div className="col-md-3">
                <div className="form-group formfeilds">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Banner">
                        Icon<span style={{ color: "red" }}> *</span>
                      </label>
                    </div>
                    <div className="col-md-12 bannerBox">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        onBlur={onBlurFileChange}
                        ref={inputRef}
                        accept=".jpg, .jpeg, .png, .webp"
                      />
                      <span className="input_info">
                        <Icon
                          icon="material-symbols:info-outline-rounded"
                          fontSize={14}
                        />
                        Supported formats: JPEG/JPG, PNG and WEBP.
                      </span>
                      <div className="row mb-4">
                        <div className="text-left text-small col-md-8 color_red">
                          {bannerError}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {bannerShow ? (
                  <div className="my-2 downloadimg">
                    <img
                      className="SchemeBanner "
                      src={bannerShow}
                      alt="Selected Banner"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <div className="languagefeilds">
              <ul className="editlanguageButton">
                {languageEntries.map((language, index) => (
                  <li
                    onClick={() => {
                      handleLanguages(index);
                    }}
                    className={
                      currentLanguageCode === language.langCode
                        ? "active"
                        : null
                    }
                  >
                    {currentLanguageCode === language.langCode ? (
                      <AiFillCheckCircle />
                    ) : null}
                    {language.sSchema}{" "}
                  </li>
                ))}
              </ul>
            </div>

            <div className="tabsGroup ">
              <div className="form-group formfeilds">
                <div className="row">
                  <div className="col-md-2">
                    <label htmlFor="englishName">
                      Title
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="FormControl"
                      onChange={(e) => setName(e.target.value)}
                      placeholder={placeholderTitle}
                      maxLength={50}
                      onBlur={isValidName}
                      value={name}
                    />
                    <div className="row">
                      <div className="text-left text-small col-md-8 color_red">
                        {currentLanguageError
                          ? currentLanguageError.error
                          : nameError}
                      </div>
                      <div className="text-right text-small col-md-4">
                        Max limit: 50
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-primary ml-4" type="submit">
            Save
          </button>
        </form>
      </div>
      <AlertModal
        open={openUpdateAlert}
        handleClose={handleClose}
        handleOk={handleClose}
        title={response}
        showCancel={false}
      />
    </>
  );
};

export default CategoryAddorUpdate;
