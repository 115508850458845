import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCheckCircle } from "react-icons/ai";
import HtmlEditor from "../../components/HTMLEditor/HtmlEditor";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { setLoader } from "../../features/FullPageLoader/FullPageLoaderSlice";
import { apiHandler } from "../../api";
import { endpoint } from "../../api/endpoint";
import { useLocation, useNavigate } from "react-router";
import AlertModal from "../../components/Alert/AlertModal";
import { useToasts } from "react-toast-notifications";
import { EditorState } from "draft-js";
import { Icon } from "@iconify/react";
import { IoArrowBackCircle } from "react-icons/io5";

const SchemaAddorUpdate = () => {
  const inputRef = useRef(null);
  const { state } = useLocation();
  const { addToast } = useToasts();
  const { languageEntries } = useSelector((state) => state.appConfigSlice);
  const { authData } = useSelector((state) => state.loginSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    languageEntries[0].langCode
  );
  const [currentLanguageIndex, setCurrentLanguagIndex] = useState(0);

  const [tabChanged, setTabChanged] = useState(1);

  const [enabled, setEnabled] = useState(true);
  const [id, setID] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [ROI, setROI] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerShow, setBannerShow] = useState(null);

  const [requestObject, setRequestObject] = useState([]);

  const [nameError, setNameError] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [ROIError, setROIError] = useState(false);

  const [response, setResponse] = useState("");
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false);

  const [newIndex, setNewIndex] = useState(0);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleClose = () => {
    setOpenUpdateAlert(false);
    if (response) {
      navigate("/schemes", { replace: true });
    }
    setResponse("");
  };

  useEffect(() => {
    if (response !== "") {
      setOpenUpdateAlert(true);
    }
  }, [response]);

  const getSchemeDetails = useCallback(
    async (id) => {
      dispatch(setLoader(true));

      const languageCodes = languageEntries.map(
        (language) => language.langCode
      );

      const updatedRequestObject = [...requestObject];

      for (const langCode of languageCodes) {
        const existingEntryIndex = updatedRequestObject.findIndex(
          (entry) => entry.langCode === langCode
        );

        if (existingEntryIndex === -1) {
          const result = await apiHandler({
            url: endpoint.GETSINGLESCHEME + id,
            method: "GET",
            authToken: authData.accessToken,
            refreshToken: authData && authData.refreshToken,
            headers: { langcode: langCode },
            dispatch,
            navigate,
          });

          if (result.data.status === "success") {
            const scheme = result.data.data[0];
            if (!requestObject.length) {
              setEnabled(scheme.bEnabled === 1);
              setID(scheme.iSchemeId);
              setROI(scheme.dROI);
              setBannerImage(scheme.sBannerURL);
              setBannerShow(scheme.sBannerURL);
            }

            updatedRequestObject.push({
              langCode: langCode,
              sSchemeTitle: scheme.sSchemeTitle,
              sSchemeContent: scheme.sSchemeContent,
            });
          } else {
            console.log("getSchemeDetails : result.data", result.data);
          }
        }
      }
      setName(updatedRequestObject[newIndex].sSchemeTitle);
      setDescription(updatedRequestObject[newIndex].sSchemeContent);

      setRequestObject(updatedRequestObject);

      dispatch(setLoader(false));
    },
    [requestObject]
  );

  useEffect(() => {
    if (state && state.scheme) {
      let scheme = state.scheme;
      getSchemeDetails(scheme.iSchemeId);
    }
  }, [state]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      setBannerShow(null);
      inputRef.current.value = null;
      return;
    }

    if (!file.type.startsWith("image/")) {
      addToast("Please upload a valid image file.", {
        appearance: "error",
        autoDismiss: true,
      });
      setBannerShow(null);
      inputRef.current.value = null;
      setBannerImage(null);

      return;
    }

    setBannerImage(file);

    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const maxWidth = 800;
        const maxHeight = 600;

        if (img.width > maxWidth || img.height > maxHeight) {
          addToast(
            `Maximum dimensions should be ${maxWidth}x${maxHeight} or smaller.`,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          setBannerShow(null);
          setBannerImage(null);
          inputRef.current.value = null;
        } else {
          setBannerError("");
          setBannerShow(e.target.result);
        }
      };
    };

    reader.readAsDataURL(file);
  };

  const tempRef = useRef();
  const onBlurFileChange = (event) => {
    const file = event.target.files[0];
    if (!file && tempRef.current) {
      setBannerShow(null);
      setBannerError("This is a Required Field! ");
      return;
    }
    tempRef.current = tempRef + 1;
  };

  const isValidName = () => {
    if (name.trim() === "") {
      setNameError("This is a Required Field! ");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const isValidDescription = () => {
    if (description.trim() === "") {
      setDescriptionError("This is a Required Field! ");
      return false;
    }
    setDescriptionError("");
    return true;
  };

  const isValidBanner = () => {
    if (bannerShow === null) {
      setBannerError("This is a Required Field! ");
      return false;
    }
    setBannerError("");
    return true;
  };

  const isValidROI = () => {
    let parsedROI = parseFloat(ROI);
    if (ROI === "" || ROI === undefined) {
      setROIError("This is a Required Field! ");
      return false;
    }
    const ROI_REGEX = /^(0(\.\d+)?|1)$/;
    if (!ROI_REGEX.test(parsedROI)) {
      setROIError("Please enter integer Between 0 and 1");
      return false;
    }
    setROIError("");
    return true;
  };

  const validate = () => {
    if (
      isValidName() &&
      isValidDescription() &&
      isValidROI() &&
      isValidBanner()
    ) {
      return true;
    } else {
      isValidName();
      isValidDescription();
      isValidROI();
      isValidBanner();
    }
    return false;
  };

  const handleNextButton = (newIndex) => {
    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];
    // If it exists, update sSchemeTitle and sSchemeContent
    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sSchemeTitle = name;
      updatedRequestObject[existingEntryIndex].sSchemeContent = description;
    } else {
      // If it doesn't exist, add a new entry to the updatedRequestObject
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sSchemeTitle: name,
        sSchemeContent: description,
      });
    }

    setRequestObject(updatedRequestObject);

    let currentIndex = newIndex;

    const currentIndexData = requestObject.findIndex(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (currentIndexData !== -1) {
      setName(requestObject[currentIndexData].sSchemeTitle);
      setDescription(requestObject[currentIndexData].sSchemeContent);
    } else {
      setName("");
      setDescription("");
      setEditorState(EditorState.createEmpty());
    }
    setTabChanged(tabChanged + 1);
    setCurrentLanguageCode(languageEntries[currentIndex].langCode);
    setCurrentLanguagIndex(currentIndex);
  };

  const handleBackButton = (newIndex) => {
    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];
    // If it exists, update sSchemeTitle and sSchemeContent
    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sSchemeTitle = name;
      updatedRequestObject[existingEntryIndex].sSchemeContent = description;
    } else {
      // If it doesn't exist, add a new entry to the updatedRequestObject
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sSchemeTitle: name,
        sSchemeContent: description,
      });
    }

    setRequestObject(updatedRequestObject);
    let currentIndex = newIndex;

    const backData = requestObject.filter(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (backData.length) {
      setName(backData[0].sSchemeTitle);
      setDescription(backData[0].sSchemeContent);
    } else {
      setName("");
      setDescription("");
    }
    setTabChanged(tabChanged + 1);
    setCurrentLanguageCode(languageEntries[currentIndex].langCode);
    setCurrentLanguagIndex(currentIndex);
  };

  const addScheme = async (formData) => {
    const result = await apiHandler({
      url: endpoint.ADD_SCHEME,
      method: "POST",
      data: formData,
      authToken: authData.accessToken,
      refreshToken: authData && authData.refreshToken,
      dispatch,
      navigate,
    });
    return result.data;
  };

  const editScheme = async (formData) => {
    const result = await apiHandler({
      url: endpoint.EDIT_SCHEME,
      method: "PUT",
      data: formData,
      authToken: authData.accessToken,
      refreshToken: authData && authData.refreshToken,
      dispatch,
      navigate,
    });
    return result.data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    const existingEntryIndex = requestObject.findIndex(
      (entry) => entry.langCode === currentLanguageCode
    );

    const updatedRequestObject = [...requestObject];

    if (existingEntryIndex !== -1) {
      updatedRequestObject[existingEntryIndex].sSchemeTitle = name;
      updatedRequestObject[existingEntryIndex].sSchemeContent = description;
    } else {
      // If it doesn't exist, add a new entry to the updatedRequestObject
      updatedRequestObject.push({
        langCode: currentLanguageCode,
        sSchemeTitle: name,
        sSchemeContent: description,
      });
    }

    setRequestObject(updatedRequestObject);
    dispatch(setLoader(true));

    let currentIndex = newIndex;

    const currentIndexData = requestObject.findIndex(
      (entry) => entry.langCode === languageEntries[currentIndex].langCode
    );

    if (currentIndexData !== -1) {
      setName(requestObject[currentIndexData].sSchemeTitle);
      setDescription(requestObject[currentIndexData].sSchemeContent);
    } else {
      setName("");
      setDescription("");
    }

    const formData = new FormData();
    formData.append("bEnabled", enabled ? 1 : 0);
    formData.append("aSchemeData", JSON.stringify(updatedRequestObject));
    formData.append("dROI", ROI);
    formData.append("fBanner", bannerImage);

    const missingLanguages = languageEntries.filter((lang) => {
      const languageData = updatedRequestObject.find(
        (entry) => entry.langCode === lang.langCode
      );
      return (
        !languageData ||
        !languageData.sSchemeTitle ||
        !languageData.sSchemeContent
      );
    });

    if (missingLanguages.length > 0) {
      const index = languageEntries.findIndex(
        (item) => item.langCode === missingLanguages[0].langCode
      );

      const expectedLanguageCodes = missingLanguages.map((obj) => obj.sSchema);

      setTabChanged(tabChanged + 1);
      setCurrentLanguagIndex(index);
      setNewIndex(index);
      setName("");
      setDescription("");
      setEditorState(EditorState.createEmpty());
      setCurrentLanguageCode(missingLanguages[0].langCode);
      addToast(
        "Data is missing for the following languages: " +
          expectedLanguageCodes.join(", "),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } else {
      if (state && state.scheme) {
        formData.append("id", id);
        const response = await editScheme(formData);
        if (response.status === "success") {
          setResponse(response.message);
        } else {
          setResponse(response.message);
        }
      } else {
        const response = await addScheme(formData);
        if (response.status === "success") {
          setResponse(response.message);
        } else {
          setResponse(response.message);
        }
      }
    }

    dispatch(setLoader(false));
  };

  const handleLanguages = (newIndex) => {
    setNewIndex(newIndex);
    if (currentLanguageIndex < newIndex) {
      handleNextButton(newIndex);
    } else if (currentLanguageIndex > newIndex) {
      handleBackButton(newIndex);
    }
  };

  const placeholderTitle = `Title (in ${languageEntries[newIndex].sSchema})`;

  const handleBack = (event) => {
    event.preventDefault();
    navigate(-1, { replace: true });
  };

  return (
    <>
      <div className="InnerContent formfeilds">
        <div className="Row d-flex">
          <div className="col-md-11">
            <label className="Label" style={{ color: "#a75503" }}e>
              {state && state.scheme ? "Edit / Update" : "Add New Scheme"}
            </label>
          </div>
          <div className="col-md-1">
            <button className="btn btn-primary" onClick={handleBack}>
              <IoArrowBackCircle size={30} />
            </button>
          </div>
        </div>
        <hr className="mt-3 mb-5" />
        <form onSubmit={handleSubmit}>
          <div className="form-group formfeilds">
            <div className="row">
              <div className="col-md-2">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="enable">Enable Scheme</label>
                  </div>
                  <div className="col-md-12">
                    <span
                      onClick={(e) => setEnabled(!enabled)}
                      className="pointer"
                    >
                      {enabled ? (
                        <>
                          <BsToggleOn size={35} color={"Green"} />{" "}
                          <span className="color_green">ON</span>
                        </>
                      ) : (
                        <>
                          <BsToggleOff size={35} color={"Red"} />{" "}
                          <span className="color_red">OFF</span>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group formfeilds">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="ROI">
                        ROI<span style={{ color: "#ff7f00" }}> *</span>
                      </label>
                      <span className="color_yellow text-small">
                        (Rate of Interest)
                      </span>
                    </div>
                    <div className="col-md-12">
                      <input
                        className="FormControl"
                        type="text"
                        value={ROI}
                        onChange={(e) => setROI(e.target.value)}
                        onBlur={isValidROI}
                        placeholder="ROI"
                      />
                      <div className="row">
                        <div className="text-left text-small col-md-8 color_red">
                          {ROIError}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group formfeilds">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="Banner">
                        Icon<span style={{ color: "red" }}> *</span>
                      </label>
                    </div>
                    <div className="col-md-12 bannerBox">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept=".jpg, .jpeg, .png, .webp"
                        onBlur={onBlurFileChange}
                        ref={inputRef}
                      />
                      <span className="input_info">
                        <Icon
                          icon="material-symbols:info-outline-rounded"
                          fontSize={14}
                        />{" "}
                        Supported formats: JPEG/JPG, PNG and WEBP.
                      </span>
                      <div className="row">
                        <div className="text-left text-small col-md-8 color_red">
                          {bannerError}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                {bannerShow ? (
                  <div className="my-2 downloadimg">
                    <img
                      className="SchemeBanner"
                      src={bannerShow}
                      alt="Selected Banner"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div>
            <div className="languagefeilds">
              <ul className="editlanguageButton">
                {languageEntries.map((language, index) => (
                  <li
                    onClick={() => {
                      handleLanguages(index);
                    }}
                    className={
                      currentLanguageCode === language.langCode
                        ? "active"
                        : null
                    }
                  >
                    {currentLanguageCode === language.langCode ? (
                      <AiFillCheckCircle />
                    ) : null}
                    {language.sSchema}{" "}
                  </li>
                ))}
              </ul>
            </div>

            <div className="tabsGroup">
              <div className="form-group formfeilds">
                <div className="row">
                  <div className="col-md-2">
                    <label htmlFor="englishName">
                      Title
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="FormControl"
                      onChange={(e) => setName(e.target.value)}
                      placeholder={placeholderTitle}
                      maxLength={50}
                      onBlur={isValidName}
                      value={name}
                    />
                    <div className="row">
                      <div className="text-left text-small col-md-8 color_red">
                        {nameError}
                      </div>
                      <div className="text-right text-small col-md-4">
                        Max limit: 50
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group formfeilds">
                <div className="row">
                  <div className="col-md-2">
                    <label htmlFor="Description">
                      Content
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="col-md-4">
                    <Suspense fallback={null}>
                      <HtmlEditor
                        setContent={setDescription}
                        content={description}
                        tabchanged={tabChanged}
                        editorState={editorState}
                        setEditorState={setEditorState}
                      />
                    </Suspense>
                    <div className="row">
                      <div className="text-left text-small col-md-8 color_red">
                        {descriptionError}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button className="btn btn-primary ml-4" type="submit">
            Save
          </button>
        </form>
      </div>
      <AlertModal
        open={openUpdateAlert}
        handleClose={handleClose}
        handleOk={handleClose}
        title={response}
        showCancel={false}
      />
    </>
  );
};

export default SchemaAddorUpdate;
